/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.ticket-number {
    span {
        width: 24px;
        height: 24px;
        background: #f9f8ff;
        border: 1px solid #dad3ff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
        border-radius: 36px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #5949b3;
        display: inline-block;
        margin: auto 2px;
    }
}

.ticket-number-highlight {
    span {
        width: 24px;
        height: 24px;
        background: #f9f8ff;
        border: 1px solid #dad3ff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
        border-radius: 36px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #5949b3;
        display: inline-block;
        margin: auto 2px;
        &:first-child {
            background: #ff3838 !important;
            color: #fff !important;
        }
    }
}

.first-letter {
    &:first-child {
        background: #ff3838 !important;
        color: #fff !important;
    }
}

.four-letter,
.matching-letter {
    &:nth-child(1) {
        background: #ff3838 !important;
        color: #fff !important;
    }
}
